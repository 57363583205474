import {useTranslation} from "react-i18next";
import {Button, Col, Spinner, Row, Form} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import React, {useEffect, useReducer, useState, useRef} from "react";
import LoadingPage from "@/components/LoadingPage";
import Select from "react-select";
import DriverFormOptions from "./components/DriverFormOptions";
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from "react-select/async";
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Creatable from "react-select/creatable";
import {AsyncPaginate, withAsyncPaginate} from "react-select-async-paginate";
import {NotificationChannels} from "@/services";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import AccountType from "../../../services/AccountType";

function UserForm({
          task,
          buttonText,
          type,
          typeId,
          onSubmit,
          processing,
          user,
          serverErrors,
    }) {

    const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

    const requestStore = useRef({});
    const { current: requests } = requestStore;


    const {t} = useTranslation();

    let auth = useAuth();

    const [loading, setLoading] = useState(true);
    const [loadingClients, setLoadingClients] = useState(true);
    const [vehicleList, setVehicleList] = useState([]);


    const [notificationChannelList, setNotificationChannelList] = useState(null);

    const formatSupplier = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.company_name;

        return object;
    };

    const formatRecord = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.name;

        return object;
    };

    const formatVehicle = (vehicle) => {
        const  object = {...vehicle};

        object.value = vehicle.registration_number;
        object.label = vehicle.registration_number;

        return object;
    };


    const schema = yup.object().shape({
        name        : yup.string()
                         .required(t('form_validation.name_is_required')),
        primary_role: task === 'update' ? yup.string().nullable() :
                        yup.string().required(t('form_validation.user_type_is_required')),
        phone_number: yup.string()
                         .nullable(),
        email       : yup.string()
                         .email(t('form_validation.invalid_email_address'))
                         .nullable(),

        supplier_id: yup.string()
            .typeError(t('form_validation.is_required', { attribute: t('supplier') }))
            .when(['primary_role'], function (role, schema) {
                if([3, 10, 4, 5].includes(role))
                {
                    return schema
                        .required(t('form_validation.is_required', { attribute: t('supplier') }));
                }

                return schema.nullable();
            }),

        sites: yup.array()
            .typeError(t('form_validation.is_required', { attribute: t('sites') }))
            .when(['primary_role'], function (role, schema) {
                if([4, 5].includes(role))
                {
                    return schema
                        .required(t('form_validation.is_required', { attribute: t('sites') }))
                        .min(1, t('form_validation.is_required', { attribute: t('sites') })) ;
                }

                return schema.nullable();
            }),

        company_id: yup.string()
            .typeError(t('form_validation.is_required', { attribute: t('client') }))
            .when(['primary_role'], function (role, schema) {
                if([6, 11, 7, 8].includes(role))
                {
                    return schema
                        .required(t('form_validation.is_required', { attribute: t('client') }));
                }

                return schema.nullable();
            }),


        fleets: yup.array()
            .typeError(t('form_validation.is_required', { attribute: t('fleets') }))
            .when(['primary_role'], function (role, schema) {
                if([7, 8].includes(role))
                {
                    return schema
                        .required(t('form_validation.is_required', { attribute: t('fleets') }))
                        .min(1, t('form_validation.is_required', { attribute: t('fleets') })) ;
                }

                return schema.nullable();
            }),

        vehicles: yup.array()
            .typeError(t('form_validation.is_required', { attribute: t('vehicles') }))
            .when(['primary_role', 'add_all_vehicles'], function (role, add_all_vehicles, schema) {
              return role == 8 && add_all_vehicles != 1 ?
                  schema
                      .required(t('form_validation.is_required', { attribute: t('vehicles') }))
                      .min(1, t('form_validation.is_required', { attribute: t('vehicles') }))
                  : schema.nullable();
          }),
    }).required();

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        setError,
        watch,
        setValue,
        getValues
    } = useForm({
        defaultValues: (_ => {
            const values = {...user};

            if(values.supplier)
            {
                values.selected_supplier = formatSupplier(values.supplier);
            }

            if(values.company)
            {
                values.selected_company = formatRecord(values.company);
                values.company_id = values.company?.id;
            }

            if(values.fleets)
            {
                values.selected_fleets = values.fleets.map(formatRecord);
                values.fleets = values.fleets.map(_ => _.id);
            }

            if(values.user_vehicles)
            {
                values.vehicles = values.user_vehicles.map(_ => _.registration_number);
                values.selected_vehicles = values.user_vehicles.map(formatVehicle);

                if(!(values.fleets instanceof Array) || values.fleets.length == 0)
                {
                    values.fleets = user.user_vehicles.map(_ => _.fleet_id).filter(_ => !!_);
                    values.selected_fleets = user.user_vehicles.map(_ => _.fleet && formatRecord(_.fleet)).filter(_ => !!_);
                }
            }

            if(values.sites)
            {
                values.sites = values.sites.map(_ => _.id);
                values.selected_sites = user.sites.map(formatRecord);
            }

            return values;
        })(),
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        register('supplier_id');
        register('selected_supplier');
        register('sites');
        register('selected_sites');
        register('fleets');
        register('selected_fleets');
        register('company_id');
        register('selected_company');
        register('vehicles');
        register('selected_vehicles');
    }, []);

    const role = watch('primary_role');

    const addAllVehicle = watch('add_all_vehicles');

    const supplier = watch('selected_supplier');
    const sites    = watch('selected_sites');
    const selected_fleets   = watch('selected_fleets');
    const company   = watch('selected_company');
    const vehicles = watch('selected_vehicles');

    var fleets = selected_fleets;

    if(type === 'fleet')
    {
        fleets = { id: typeId };
    }
    else
    if(role == 8)
    {
        fleets = fleets && fleets instanceof Array && fleets[0] || fleets;
    }
    else
    {
        fleets = fleets && fleets instanceof Array && fleets || [fleets].filter(_ => !!_);
    }

    const onSave = (values) => {
        const emptyIndex = values.spending_caps && values.spending_caps.findIndex(_ => !_.cap_period && !_.cost_cap)
        if(emptyIndex !== false && emptyIndex > -1){
            values.spending_caps.splice(emptyIndex,1);
        }

        if(values.phone_number) {
            values.phone_number = values.phone_number.startsWith('+') ? values.phone_number : '+'.concat(values.phone_number)
        }

        if(role == 8)
        {
            let fleetIds = [];
            if (selected_fleets.constructor === Array) {
                fleetIds = selected_fleets.map((fleet) => fleet.id);
            } else if (selected_fleets.constructor === Object) {
                fleetIds = [selected_fleets.id]
            }
            values.fleets = fleetIds;
        }

        onSubmit(values)
    }

    const getSuppliers = (search, loadedOptions, { page }) => {
        requests.suppliers && requests?.suppliers?.abort && requests.suppliers.abort();

        return new Promise((resolve, reject) => {
            const pageSize = 15;
            requests.suppliers = auth.getRequest('suppliers', {
              take: pageSize,
              skip: (page - 1) * pageSize,
              order_by: 'company_name',
              search
            });

          requests.suppliers.then(({data}) => {
            const hasMore = page * pageSize < data.total

            resolve({
              options: data.suppliers.map(formatSupplier),
              hasMore,
              additional: {
                page: page + 1,
              },
            })

          })
            .catch(error => reject(error))
        });
    };

    const getSites = (search) => {
        requests.sites && requests?.sites?.abort && requests.sites.abort();

        return new Promise((resolve, reject) => {
            requests.sites = auth.getRequest((supplier || (typeId && type === 'suppliers')) && `suppliers/${(typeId && type === 'suppliers') ? typeId : supplier.id ?? supplier}/sites` || 'sites', { search, managing: true, order_by: 'name' });

            requests.sites.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getClients = (search, loadedOptions, { page }) => {
      requests.clients && requests?.clients?.abort && requests.clients.abort();

      return new Promise((resolve, reject) => {
        const pageSize = 15;
        requests.clients = auth.getRequest('/clients',
          {
            take: pageSize,
            skip: (page - 1) * pageSize,
            order_by: 'name',
            search
          });

        requests.clients.then(({data}) => {
          const hasMore = page * pageSize < data.total

            setLoadingClients(false);

          resolve({
            options: data.clients.map(formatRecord),
            hasMore,
            additional: {
              page: page + 1,
            },
          })

        })
          .catch(error => reject(error))
      });
    };

    const getFleets = (search) => {
        requests.fleets && requests?.fleets?.abort && requests.fleets.abort();

        return new Promise((resolve, reject) => {
            requests.fleets = auth.getRequest((company || (typeId && type==='client')) && `companies/${(typeId && type==='client') ? typeId : company.id ?? company}/fleets` || 'fleets', { search, include: ['spending_caps'], order_by: 'name', });

            requests.fleets.then(response => {
                    resolve(response.data.fleets.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getVehicles = () => {
        setLoading(true);
       return auth.getRequest(
           fleets && fleets.id && `fleets/${fleets.id}/vehicles` || 'vehicles',
           { take: 0 })
           .then(response => {
               setVehicleList(response.data.vehicles.map(formatVehicle))
               setLoading(false);
           })
            .catch(error => {})
    };

    useEffect(() => {
      if(notificationChannelList === null)
      {
        NotificationChannels.get().then(_ => setNotificationChannelList(_));
      }

      return () => {
            for(var key in requests)
            {
                requests[key] && requests[key].abort && requests[key].abort();
            }
        }
    }, []);

    useEffect(() => {
        if( fleets?.id ) {
            let vehicleRequest = getVehicles();

            return () => vehicleRequest?.abort && vehicleRequest.abort();
        }
    }, [selected_fleets])

  const resetSites = () => {
    setValue('sites', null)
    setValue('selected_sites', null)
  }
  const resetFleets = () => {
    setValue('fleets', null)
    setValue('selected_fleets', null)
  }

  const setSelectedFleet = () => {
      if(  user?.user_vehicles?.length != null && user?.primary_role == 8){
          setValue('selected_fleets', user.user_vehicles[0]?.fleet)
          setValue('fleets', user.user_vehicles[0]?.fleet?.id)
      }
  }

    useEffect(() => {
        if( !loadingClients ) {
            setValue('fleets', null)
            setValue('selected_fleets', null)
        }
    }, [company]);

    useEffect(() => {
        setSelectedFleet();
    }, []);

    useEffect(() => {
        if( task !== 'update' ) {
            if (auth.user.is_supplier_admin || auth.user.is_site_manager) {
                setValue('selected_supplier', auth.user.supplier)
                setValue('supplier_id', auth.user.supplier?.id)
            }
            if (auth.user && (auth.user.is_client_admin || auth.user.is_fleet_manager)) {
                setValue('selected_company', auth.user.company ?? (user?.user?.company_id ?? null))
                setValue('company_id', auth.user.company?.id ?? (user?.user?.company_id ?? null))
            }
        }
    }, []);

    useEffect(() => {
        if( type === 'client' && typeId ){
            setValue('company_id', typeId);
        }
    }, []);

    useEffect(() => {
        if(user?.fleets ){
            setValue('selected_fleets', user.fleets.map(formatRecord));
        }
    }, []);

    return (
        <Form noValidate onSubmit={handleSubmit(onSave)}>
            <Row>
                <Col md={4} sm={6}>
                    <Form.Label className="my-2">
                        {t('name')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('enter_name')}
                        {...register('name')}
                        isInvalid={!!errors.name}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.name && errors.name.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={4} sm={6}>
                    <Form.Label className="my-2">
                        {t('email_address')}
                    </Form.Label>

                    <Form.Control
                        type="text"
                        placeholder={t('enter_email_address')}
                        {...register('email')}
                        isInvalid={!!errors.email}
                    />

                    <Form.Control.Feedback type="invalid">
                        {errors.email && errors.email.message}
                    </Form.Control.Feedback>
                </Col>

                <Col md={4} sm={6}>
                  <Form.Label className="my-2">
                    {t('phone_number')}
                  </Form.Label>

                  <Controller
                    control={control}
                    {...register('phone_number')}
                    isInvalid={!!errors.phone_number}
                    render={({field: { ref, ...field }}) => (
                      <PhoneInput
                        country={'gb'}
                        {...field}
                        inputExtraProps={{
                          ref
                        }}
                        inputStyle={{
                          padding: '0.55rem 14px 0.55rem 60px',
                          width: 'inherit'
                        }}
                        placeholder={t('enter_phone_number')}
                      />
                    )}
                  />


                  <Form.Control.Feedback type="invalid">
                    {errors.phone_number && errors.phone_number.message}
                  </Form.Control.Feedback>
                </Col>

                {
                    task === 'create' &&
                    <Col md={4} sm={6}>
                        <Form.Label className="my-2">
                            {t('account_type')}
                        </Form.Label>

                        <Form.Select
                            {...register('primary_role')}
                            placeholder={t('enter_account_type')}
                            isInvalid={!!errors.primary_role}
                        >
                            <option value="">{t('choose')}</option>
                            {
                                AccountType.roles(auth, type, t).map(role => <option key={role.id} value={role.id}>{role.name}</option>)
                            }
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.primary_role && errors.primary_role.message}
                        </Form.Control.Feedback>
                    </Col>
                }

                {
                    task === 'create' && ((role == 3 || role == 4 || role == 5 || role == 10) && !['supplier', 'suppliers', 'site', 'sites'].includes(type) && (auth.user.is_supplier_admin || auth.user.is_platform_admin)) &&

                    <Col md={4} sm={6}>
                        <Form.Label className="my-2">
                            {t('supplier')}
                        </Form.Label>

                        {
                            task === 'create' &&
                            <AsyncPaginate
                                className={(!!errors.supplier_id ? 'is-invalid' : '')}
                                isClearable={true}
                                value={supplier}
                                onChange={(e) => {
                                    setValue('supplier_id', e && e.value);
                                    setValue('selected_supplier', e);
                                    resetSites()
                                }}
                                placeholder={t('supplier')}
                                defaultOptions={true}
                                loadOptions={getSuppliers}
                                additional={{
                                  page: 1,
                                }}
                            />
                        }

                        <Form.Control.Feedback type="invalid" className={!!errors.supplier_id ? 'd-block' : ''}>
                            {errors.supplier_id && errors.supplier_id.message}
                        </Form.Control.Feedback>
                    </Col>
                }

                {
                    ((role == 4 || role == 5) && type !== 'site' && (supplier || (typeId && type==='suppliers'))) &&

                    <Col md={4} sm={6}>
                        <Form.Label className="my-2">
                            {t('drawer_link_titles.sites')}
                        </Form.Label>

                        <AsyncSelect
                            key={supplier?.label ?? supplier}
                            className={(!!errors.sites ? 'is-invalid' : '')}
                            isMulti
                            isClearable={true}
                            value={sites}
                            onChange={(e) => {
                                setValue('sites', e && e.map(_ => _.value));
                                setValue('selected_sites', e);
                            }}
                            placeholder={t('drawer_link_titles.sites')}
                            defaultOptions={true}
                            loadOptions={getSites}
                        />

                        <Form.Control.Feedback type="invalid" className={!!errors.sites ? 'd-block' : ''}>
                            {errors.sites && errors.sites.message}
                        </Form.Control.Feedback>
                    </Col>
                }

                {
                    ((role == 6 || role == 7 || role == 8 || role == 11) && type !== 'client' && type !== 'fleet' && (auth.user.is_supplier_admin || auth.user.is_platform_admin)) &&

                    <Col md={4} sm={6}>
                        <Form.Label className="my-2">
                            {t('client')}
                        </Form.Label>

                        {
                            task === 'create' &&
                            <AsyncPaginate
                                className="react-select"
                                isClearable={true}
                                value={company}
                                onChange={(e) => {
                                    setValue('company_id', e && e.value);
                                    setValue('selected_company', e);
                                    resetFleets();
                                }}
                                placeholder={t('client')}
                                defaultOptions={true}
                                loadOptions={getClients}
                                additional={{
                                  page: 1,
                                }}
                            />
                        }
                        {
                            task === 'update' &&
                            <input
                                disabled
                                type="text"
                                value={company && company.label}
                                placeholder={t('client')}
                                className={`form-control ${!!errors.company_id ? 'is-invalid' : ''}`}
                            />
                        }

                        <Form.Control.Feedback type="invalid" className={!!errors.company_id ? 'd-block' : ''}>
                            {errors.company_id && errors.company_id.message}
                        </Form.Control.Feedback>
                    </Col>

                }
                {
                    (role == 7 || role == 8) && type !== 'fleet' && (company || (typeId && type==='client')) &&

                    <Col md={4} sm={6}>
                        <Form.Label className="my-2">
                            {t('drawer_link_titles.fleets')}
                        </Form.Label>

                        <AsyncSelect
                            defaultValue={fleets}
                            key={'fleets' + company?.id ?? company}
                            isMulti={role != 8}
                            className="react-select"
                            isClearable={true}
                            onChange={(e) => {
                                setValue('fleets', e instanceof Array && e.map(_ => _.value) || [e && e.value]);
                                setValue('selected_fleets', e);
                            }}
                            placeholder={t('drawer_link_titles.fleets')}
                            defaultOptions={true}
                            loadOptions={getFleets}
                        />

                        <Form.Control.Feedback type="invalid" className={!!errors.fleets ? 'd-block' : ''}>
                            {errors.fleets && errors.fleets.message}
                        </Form.Control.Feedback>
                    </Col>
                }
                {
                    role == 8 && (fleets?.id || type === 'fleet') &&

                    <Col md={4} sm={6}>
                        <Form.Label className="my-2">
                            {t('drawer_link_titles.vehicles')}
                        </Form.Label>

                        <Form.Label className="d-flex">
                            <input
                                {...register('add_all_vehicles')}
                                value='1'
                                className="form-check-input horizontal-check-labels d-flex mb-2 mx-2"
                                type="checkbox"
                            />
                            Add all fleet vehicles
                        </Form.Label>

                        <Select
                            key={'vehicles' + (company?.id ?? company) + fleets?.id}
                            className="react-select"
                            isMulti
                            isClearable={true}
                            value={vehicles}
                            onChange={(e) => {
                                setValue('vehicles', e.map(_ => _.value));
                                setValue('selected_vehicles', e);
                            }}
                            placeholder={t('drawer_link_titles.vehicles')}
                            defaultOptions={true}
                            options={vehicleList}
                            isDisabled={addAllVehicle === '1'}
                            isLoading={loading}
                        />

                        <Form.Control.Feedback type="invalid" className={!!errors.vehicles ? 'd-block' : ''}>
                            {errors.vehicles && errors.vehicles.message}
                        </Form.Control.Feedback>
                    </Col>
                }
            </Row>
            {
                role == 8 && ((fleets && typeof fleets === 'object') || type === 'fleet') &&
                <Row className="position-relative">
                    {
                        <DriverFormOptions
                            {...{
                                errors,
                                watch,
                                control,
                                register,
                                setValue,
                                fleet: fleets,
                            }}
                        />
                    }
                </Row>
            }
            { notificationChannelList &&
              <Row>
                <Col md={4} sm={6}>
                  <Form.Label className="my-2">
                    {t('preferred_notification_channel')}
                  </Form.Label>

                  <Form.Select
                    {...register('preferred_notification_channel')}
                    isInvalid={!!errors.preferred_notification_channel}
                  >
                    {
                      (!notificationChannelList ? [] : Object.keys(notificationChannelList)).map(
                        key => <option key={key} value={key}>{notificationChannelList[key]}</option>
                      )
                    }
                  </Form.Select>

                  <Form.Control.Feedback type="invalid">
                    {errors.preferred_notification_channel && errors.preferred_notification_channel.message}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            }
            {
                (serverErrors.length !== 0) &&
                <div className="form-group mt-4">
                    {
                        serverErrors.map((error, index) => <p className="text-danger font-weight-bold" key={index}>{t(error)}</p>)
                    }
                </div>
            }
            <div className="form-group mt-4">
                <div className="d-grid gap-2">
                    {!processing ?
                        <Button type="submit" variant="primary">{t(buttonText)}</Button>
                        :
                        <Button variant="primary" disabled>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="mx-2">{t('please_wait')}</span>
                        </Button>
                    }
                </div>
            </div>
        </Form>
    )
}

export default UserForm;
