import {useTranslation}                               from "react-i18next";
import {useAuth, vehicle_types}                       from "@/services";
import {Link, useParams}                              from "react-router-dom";
import React, {useEffect, useState, useMemo}                   from "react";
import {Button, Card, Col, Row, Spinner, Table, Form} from "react-bootstrap";
import LoadingButton                                  from "@/components/LoadingButton";
import Select                                         from "react-select";
import { Helmet } from "react-helmet";
import * as csv from "csv-stringify/lib/sync";
import FileSaver from 'file-saver';
import LoadingPage                                    from "@/components/LoadingPage";
import ImportUploadForm from "../../../components/Forms/ImportUploadForm";

function ImportVehicles() {
    const {t, i18n: i18n} = useTranslation();
    let auth = useAuth();
    let {id} = useParams();
    const getRequest = auth.getRequest;
    const importEndpoint = '/vehicles/import'

    const [success, setSuccess] = useState(false);
    const [downloadingFile, setDownloadingFile] = useState(false);
    const [importSchema, setImportSchema] = useState(null);

    const [fleet, setFleet] = useState('');
    const [fleets, setFleets] = useState([]);

    const vehicle_types_map = useMemo(() => {
        const map = {};

        vehicle_types.map(vehicle_type => {
            map[t(`vehicle_types.${vehicle_type}`)] = vehicle_type
        })

        return map;
    }, [t])

    const filterInput = (record, attribute_keys, i) => {
        var key = attribute_keys.vehicle_type;

        if(key >= 0)
        {
            record[key] = vehicle_types_map[ record[key] ] || record[key];
        }

        return record;
    }

    const downloadSampleFile = () => {
        setDownloadingFile(true);
        getRequest('vehicles/import-sample')
            .then(response => {
                const records = response.data.sample_vehicles;
                const headers = importSchema.map(column => t(column));

                var index = importSchema.findIndex(_ => _ == 'vehicle_type');

                records.forEach(record => {

                    if(index >= 0)
                    {
                        record[index] = t(`vehicle_types.${record[index]}`);
                    }
                })

                records.unshift(headers);

                const data = "\uFEFF" + csv.stringify(records, {});
                const blob = new Blob([data], {type: 'text/plain;charset=utf-8', encoding: 'UTF-8'});

                FileSaver.saveAs(blob, 'vehicles.csv', {});
                setDownloadingFile(false);
            })
            .catch(error => {
                console.error(error);
                setDownloadingFile(false);
            })
    }

    useEffect(() => {
        if(importSchema === null)
        {
            let request = auth.getRequest(importEndpoint);

            request
                .then(response => {
                    setImportSchema(Object.keys(response.data.schema));
                })
                .catch(error => {
                })

            return () => request?.abort && request.abort();

        }
    }, [importSchema]);


    return (
        <div>
            {
                success &&
                <div className="d-flex justify-content-center align-items-center" style={{minHeight: 400}}>
                    <Card border="light">
                        <Card.Body className="text-center">
                            <i className="bi bi-check-circle" style={{fontSize: "10rem"}}/>
                            <Card.Title>{t('vehicles_imported')}</Card.Title>
                            <Card.Text>
                                {t('all_vehicles_are_imported')}
                            </Card.Text>
                            <div className="d-grid gap-2">
                                <Link variant="primary" className="btn btn-primary" to={`/vehicles`}>{t('go_to_vehicles')}</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            }
            {
                !success &&
                <Card className="mx-2 my-2 p-2">
                    <Helmet>
                        <title>{t('import')} / {t('drawer_link_titles.vehicles')} - {t('app')}</title>
                    </Helmet>

                    <Card.Body className="px-2">
                        <Row>
                            <Col className="col-12 mb-4">
                                <h5>{t('choose_csv_file_to_upload')}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12 mb-4 d-flex justify-content-between">
                                <h6>{t('csv_file_should_contain_the_following_columns')}</h6>
                                <LoadingButton loading={downloadingFile} variant="success" titleTranslationKey="download_sample_file" onSubmit={() => downloadSampleFile()}/>
                            </Col>
                            <Col className="col-12 mb-4">
                                {
                                    importSchema && (<Table striped bordered hover className="mt-1">
                                    <thead>
                                    <tr>
                                        <th>{t('column')}</th>
                                        <th>{t('example')}</th>
                                        <th>{t('notes')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        importSchema.includes('account_number') &&
                                        <tr>
                                            <td>{t('account_number')}</td>
                                            <td>123456789</td>
                                            <td>{t('required')}</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('vehicle_registration_number') &&
                                        <tr>
                                            <td>{t('vehicle_registration_number')}</td>
                                            <td>GVQ 8879</td>
                                            <td>{t('required')}</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('vehicle_type') &&
                                        <tr>
                                            <td>{t('vehicle_type')}</td>
                                            <td>{t('vehicle_types.Truck')}</td>
                                            <td>

                                                {t('options')}
                                                <ul>
                                                    {vehicle_types.map((vehicle_type, index) => (
                                                        <li key={vehicle_type}>{t(`vehicle_types.${vehicle_type}`)}</li>
                                                    ))}
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('fleet_name') &&
                                        <tr>
                                            <td>{t('fleet_name')}</td>
                                            <td>Fleet Name</td>
                                            <td>{t('required')}</td>
                                        </tr>
                                    }
                                    {
                                        importSchema.includes('drivers') &&
                                        <tr>
                                            <td>{t('drivers')}</td>
                                            <td>jdoe@example.com or +44771234567</td>
                                            <td>{t('optional')}</td>
                                        </tr>
                                    }
                                    </tbody>
                                </Table>)
                                    || <LoadingPage/>
                                }
                            </Col>

                        </Row>
                        <ImportUploadForm
                            importSchema={importSchema}
                            endpoint={importEndpoint}
                            fields={fleet && typeof fleet === 'object' ? {'fleet_id': fleet.value} : null}
                            onSuccess={()=>{setSuccess(true)}}
                            onFailure={(error)=>{
                                if(error.fleets) {
                                    setFleets(error.fleets);
                                }
                            }}
                            filter={filterInput}
                        ></ImportUploadForm>

                        {
                            fleets.length !== 0 &&
                            <Row>
                                <Col md={6} className="col-12">
                                    <Select
                                        className="react-select"
                                        isClearable={true}
                                        value={fleet}
                                        onChange={(e) => setFleet(e)}
                                        placeholder={t('fleet')}
                                        options={fleets.map(fleet => {
                                            return {value: fleet.id, label: fleet.name}
                                        })}/>
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                </Card>
            }
        </div>
    )
}

export default ImportVehicles;
