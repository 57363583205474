import {Link, useParams}               from "react-router-dom";
import {useTranslation}                from "react-i18next";
import {useAuth}                       from "@/services/Auth";
import React, {useEffect, useState}    from "react";
import LoadingPage                     from "@/components/LoadingPage";
import {Button, Card, Col, Row, Table} from "react-bootstrap";
import ConfirmDialog from "@/components/Modals/ConformDialog";
import SuccessDialog from "@/components/Modals/SuccessDialog";
import OrderItems from "@/components/Tables/OrderItems";
import Users from "@/components/Tables/Users";
import Vehicles from "@/components/Tables/Vehicles";
import { Helmet } from "react-helmet";
import AccountType from "../../../services/AccountType";
import UserSelect from "../../../components/Modals/UserSelect";
import useMoment from "@/hooks/useMoment";

function FleetSingle(props) {

    const {momentLocal, moment} = useMoment()

    const {t, i18n} = useTranslation();

    let auth = useAuth();

    const getRequest = auth.getRequest;
    let {id} = useParams();


    const [fleet, setFleet] = useState(null);
    const [loading, setLoading] = useState(true);
    const [canUpdateFleet, setCanUpdateFleet] = useState(false);
    const [canDelete, setCanDelete] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userSelectErrors, setUserSelectErrors] = useState({});
    const [confirmSelectUser, setConfirmSelectUser] = useState(false);
    const [userSeed, setUserSeed] = useState(null);

    const revokeAccess = (userId) => {
        auth.deleteRequest(`/fleets/${id}/users/${userId}/revoke-access`)
            .then(response => {
                setUserSeed(Math.random())
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const deleteFleet = () => {

        setConfirmDelete(false);
        setLoading(true);
        auth.deleteRequest(`/fleets/${id}`)
            .then(response => {
                setLoading(false);
                setFleet(null);
                setDeleteSuccess(true);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const addUser = (userEmail, roleId) => {
        setUserSelectErrors({})
        if (!userEmail) {
            setUserSelectErrors({email: 'Email is Required'})
            return;
        }
        if (!roleId) {
            setUserSelectErrors({role: 'Role is Required'})
            return;
        }
        setConfirmSelectUser(false);
        setLoading(true);
        const data = {
            'email': userEmail,
            'role': roleId
        }
        auth.postRequest(`/fleets/${id}/users/add-existing`, data)
            .then(response => {
                setUserSeed(Math.random())
                setLoading(false);
            })
            .catch(error => {
                const laravelErrors = error.response.data.errors;
                const errors = {};
                for (const errorKey in laravelErrors) {
                    errors[errorKey] = laravelErrors[errorKey][0]
                }
                setUserSelectErrors(errors)
                setLoading(false);
                setConfirmSelectUser(true);
            })
    }

    useEffect(() => {
        if(fleet === null)
        getRequest('/fleets/' + id, { include: 'all' })
            .then(response => {
                setRoles(AccountType.roles(auth, 'fleet', t));
                setFleet(response.data.fleet);
                setCanUpdateFleet(response.data.can_update);
                setCanDelete(response.data.can_delete);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })

    }, [fleet]);

    return (
        !fleet || loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{fleet?.deleted_at && t('archived') || ''} {fleet.name} / {t('drawer_link_titles.fleets')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={deleteSuccess}
                    message="record_deleted"
                    handleClose={_ => setDeleteSuccess(false)}
                />

                <ConfirmDialog
                    cancel='conform_dialog.cancel'
                    accept='conform_dialog.delete'
                    acceptAction={deleteFleet}
                    show={confirmDelete}
                    handleClose={_ => setConfirmDelete(false)}
                />

                <UserSelect
                    cancel='conform_dialog.cancel'
                    accept='confirm'
                    roles={roles}
                    errors={userSelectErrors}
                    acceptAction={addUser}
                    show={confirmSelectUser}
                    handleClose={_ => setConfirmSelectUser(false)}
                />

                <Card.Subtitle className="d-flex justify-content-between" style={{width: '100%'}}>
                    <h3 className="text-primary">{fleet?.deleted_at && t('archived') || ''} {fleet.name}</h3>
                    <div>
                        {canDelete && <Button variant="danger" className="mx-1" onClick={() => setConfirmDelete(true)}>{t('delete')}</Button>}
                        {
                            canUpdateFleet &&
                            <Link to={`/fleets/${fleet.id}/users/import`} className="btn btn-success me-2">{t('import_drivers')}</Link>
                        }

                        {
                            canUpdateFleet &&
                            <Link to={`/fleets/edit/${fleet.id}`} className="btn btn-primary">{t('edit')}</Link>
                        }
                    </div>
                </Card.Subtitle>
                <Row className="mt-2">
                    <Col md={8}>
                        <Row className="mt-2">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{t('orders.recent_orders')}</h5>
                                    <Link to={`/orders/fleet/${fleet.id}`} className="btn btn-primary">{t('orders.view_all_orders')}</Link>
                                </div>

                                <OrderItems url={`/fleets/${fleet.id}/order_items`} perPage={10} paginate={false} exclude={['fleet', 'account_number']} />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{t('drawer_link_titles.users')}</h5>
                                    <div>
                                        {
                                            auth?.roles?.create_user &&
                                            <Button variant="secondary" className="me-2"
                                                    onClick={() => setConfirmSelectUser(true)}>{t('add_user')}</Button>
                                        }
                                        {
                                            auth?.roles?.create_user &&
                                            <Link to={`/users/create/fleet/${fleet.id}`} className="btn btn-secondary me-2">{t('new_user')}</Link>
                                        }

                                        <Link to={`/fleets/${fleet.id}/users`} className="btn btn-primary">{t('view_all_users')}</Link>
                                    </div>
                                </div>

                                <Users key={userSeed} url={`/fleets/${fleet.id}/users`}
                                       revokeAccess={revokeAccess}
                                       exclude={['fleet', 'client', 'account_number', 'site', 'supplier']} />
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h5 className="mb-0">{t('drawer_link_titles.vehicles')}</h5>
                                    {
                                        auth?.roles?.create_vehicle &&
                                        <Link to={`/vehicles/fleet/create/${fleet.id}`} className="btn btn-secondary me-2">{t('new_vehicle')}</Link>
                                    }
                                </div>

                                <Vehicles url={`/fleets/${fleet.id}/vehicles`} exclude={['fleet', 'client']} />
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4}>
                        <ol className="list-group">

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <h6>{t('fleet_details')}</h6>
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('primary_contact')}</div>
                                    {fleet.contact_name}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('email_address')}</div>
                                    {fleet.contact_email}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('number_of_drivers')}</div>
                                    {fleet.drivers_count}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('compliance_licences')}</div>
                                    {fleet.compliance_licences}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('price_per_licence')}</div>
                                    {(() => {
                                        var total = fleet?.account_items?.reduce && fleet.compliance_licences ? (fleet.account_items?.reduce((total, item) => (item.unit_price * item.quantity) + total, 0) / fleet.compliance_licences ) : 0;

                                        return total ? total.toFixed(2) : ''
                                    })()}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('compliance.title') + ' ' + t('last_updated')}</div>
                                    {(() => {
                                        var last_updated = fleet?.account_items?.reduce ? fleet.account_items?.reduce((last_updated, item) => last_updated ? moment.max(last_updated, momentLocal(item.updated_at)) : momentLocal(item.updated_at), '') : '';

                                        return last_updated ? last_updated.format(t('formats.date')) : '';
                                    })()}
                                </div>
                            </li>

                        </ol>

                        <ol className="list-group mt-3">

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <h6>{t('company_details')}</h6>
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('client_name')}</div>
                                    {fleet.client ? <Link to={`/clients/${fleet.client.id}`}>{fleet.client.name}</Link> : null}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('address')}</div>
                                    {fleet.client ? fleet.client.address : null}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('email_address')}</div>
                                    {fleet.client ? fleet.client.email : null}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('phone_number')}</div>
                                    {fleet.client ? fleet.client.phone_number : null}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('account_number')}</div>
                                    {fleet.client ? fleet.client.account_number : null}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('account_status')}</div>
                                    {fleet.client ? t(fleet.client.account_status_defined) : null}
                                </div>
                            </li>

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('account_type')}</div>
                                    {fleet.client ? t(fleet.client.account_type) : null}
                                </div>
                            </li>


                            {
                                (fleet.client && fleet.client.account_type === 'Credit') &&
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('credit')}</div>
                                        {fleet.client.credit_used}&nbsp;{fleet.client.currency} / {fleet.client.credit_limit}&nbsp;{fleet.client.currency}
                                    </div>
                                </li>
                            }

                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                    <div className=" text-black-50">{t('client_payment_terms')}</div>
                                    {
                                        fleet.client &&
                                        <span> {fleet.client.client_payment_terms_days}&nbsp;{t('days')}&nbsp; {fleet.client.client_payment_terms}</span>
                                    }
                                </div>
                            </li>

                            {
                                (fleet.client && fleet.client.account_type !== 'Credit') &&

                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className=" text-black-50">{t('payment_method')}</div>
                                        <div className="d-flex flex-column">
                                            {
                                                fleet.payment_method ?
                                                <div className="">
                                                    <span className="text-uppercase">{fleet.payment_method.brand}</span>
                                                    &nbsp;{fleet.payment_method.number}
                                                </div>
                                                                     :
                                                <span className="text-danger">{t('none')}</span>
                                            }
                                        </div>
                                    </div>
                                </li>
                            }
                        </ol>
                    </Col>
                </Row>
            </Card>
        )
    )
}

export default FleetSingle;
