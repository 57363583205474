import {DistanceMatrixService} from '@react-google-maps/api';
import React, {useEffect, useState, useCallback} from "react";
import { hashJourney, parseCoordinate } from "@/services/Sites";
import {useAuth}                     from "@/services/Auth";
import {convertDistance} from "../../../../services/JourneyStore";

function DistanceMatrixServiceComponent(props) {
    const [destinationList, setDestinationList] = useState([]);
    const [sent, setSent] = useState(false);
    const [updatedDestinations, setUpdatedDestinations] = useState({});

    const [distanceRetry, setDistanceRetry] = useState(0)

    let auth = useAuth();

    useEffect(() => {
        prepareDestinations(0);
    }, []);

    const prepareDestinations = useCallback((currentCounter) => {
        let destinationUpdate = [];

        props.destinations.forEach((destination, index) => {
            if (index >= currentCounter && index <= (currentCounter + 24)) {
                destinationUpdate.push(parseCoordinate(destination));
            }
        });
        setDestinationList(destinationUpdate);
    });

    const responseCallback = (response, status, destinationList) => {
        if (status == 'OK' && response != null) {
            response.rows && response.rows.forEach(row => {
                row.elements && row.elements.forEach((result, index) => {
                    if (result.status === 'OK') {
                        updatedDestinations[hashJourney(props.origins, destinationList[index])] = {
                            distance      : convertDistance(result.distance, auth.user),
                            duration      : result.duration,
                            calculated_at : new Date().getTime(),
                        };
                    }
                    else
                    {
                        updatedDestinations[hashJourney(props.origins, destinationList[index])] = false;
                    }
                })
            });

            setDestinationList([]);
            setUpdatedDestinations(updatedDestinations);

            if (Object.keys(updatedDestinations).length < props.destinations.length) {

                setDistanceRetry(() => (distanceRetry + 1));


                if( distanceRetry < 5 ) {
                    setTimeout(() => {
                        prepareDestinations(Object.keys(updatedDestinations).length);
                    }, 200);
                }
                else{
                    props.updateDistanceInformation(updatedDestinations);
                    setSent(true);
                }
            } else {
                props.updateDistanceInformation(updatedDestinations);
                setSent(true);
            }
        }
        else
        if(Object.keys(updatedDestinations).length)
        {
            props.updateDistanceInformation(updatedDestinations);
            setSent(true);
        }
        else
        {
            setTimeout(() => {
                prepareDestinations(Object.keys(updatedDestinations).length);
            }, 1000);
        }
    }

    if(!sent && destinationList.length)
    {
        return (
            <DistanceMatrixService
                options={{
                    destinations: destinationList,
                    origins     : [props.origins],
                    travelMode  : "DRIVING",
                }}
                callback={(response, status) => {
                    responseCallback(response, status, destinationList);
                }}
            />
        );
    }

    return <></>;
}

export default DistanceMatrixServiceComponent;
