import {useTranslation} from "react-i18next";
import {useAuth} from "@/services/Auth";
import {Link, Redirect, useParams, useHistory} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import LoadingPage from "@/components/LoadingPage";
import {Button, Card, Col, Form, Row, Spinner} from "react-bootstrap";
import ConfirmDialog from "../../../components/Modals/ConformDialog";
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import * as moment from "moment-timezone";
import {Helmet} from "react-helmet";
import SuccessBooking from "../instant_booking/create/SuccessBooking";

function Edit({location, ...props}) {
    const {t} = useTranslation();

    const history = useHistory();

    let auth = useAuth();
    let {id} = useParams();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [booking, setBooking] = useState(location.state && location.state.booking);

    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);

    const [confirmExtend, setConfirmExtend] = useState(false);

    const [successText, setSuccessText] = useState(location.state && location.state.successText);
    const [parkingPeriod, setParkingPeriod] = useState('');

    const schema = yup.object().shape({
        action: yup.string(),

        services: yup.array()
            .when(['action'], (action, schema) => {
                return action == 'update' && schema
                        .typeError(t('form_validation.is_required', {attribute: t('addons_services')}))
                        .required(t('form_validation.is_required', {attribute: t('addons_services')}))
                    || schema.nullable();
            }),

        to_datetime: yup
            .date()
            .typeError(t('form_validation.is_required', {attribute: t('to')}))
            .when(['action'], (action, schema) => {
                return action == 'extend' && booking.to_datetime && schema
                        .typeError(t('form_validation.is_required', {attribute: t('to')}))
                        .required(t('form_validation.is_required', {attribute: t('fields.to_datetime')}))
                        .min(moment(booking.to_datetime).add(45,'minute'), t('booking_cant_be_extend_to_before_initial_expire_date'))
                    || schema.nullable();
            })

    }).required();

    const {
        handleSubmit,
        register,
        formState: {errors},
        watch,
        setValue
    } = useForm({
        defaultValues: (_ => {
            const booking = {..._};

            booking.action = 'update';
            booking.to_datetime = booking.to_datetime && moment(booking.to_datetime).add(1, 'hour').format('YYYY-MM-DDTHH:mm');

            return booking;
        })(booking || {}),
        resolver: yupResolver(schema),
    });

    const updateBooking = (values, confirmed) => {
        console.log(values);
        if (values.action == 'extend' && confirmed != 'confirmed') {
            setConfirmExtend(true);
            return;
        }

        setUpdating(true);
        handleClose();

        values.token = location.state.token;
        values.services = values?.services?.filter && values.services.filter(_ => !!parseInt(_)) || null;

        auth.putRequest(`/bookings/${booking.id}`, values)
            .then(response => {
                if (response.data.message === 'OK') {
                    setBooking(response.data.booking);

                    if (values.action === 'extend') {
                        setSuccessText('booking_extended');
                        setParkingPeriod('your_booking_has_been_extended');
                    } else if (values.action === 'check_out') {
                        setSuccessText('you_have_been_checked_out');
                    } else if (values.action === 'check_in') {
                        setSuccessText('driver_have_been_checked_in');
                        setParkingPeriod('driver_parking_period_expires_on');
                    } else {
                        setSuccessText('order_has_been_updated');
                    }
                }
            })
            .finally(error => {
                setUpdating(false);
                setValue('action', 'update');
            })
    }

    const checkIn = (values) => updateBooking({...values, action: 'check_in'});
    const checkOut = (values) => updateBooking({...values, action: 'check_out'});
    const extendConfirmed = (values) => updateBooking(values, 'confirmed');

    const handleClose = () => {
        setConfirmExtend(false);
    }

    useEffect(() => {
        if (!booking || booking.id != id) {
            auth.getRequest('/bookings/' + id)
                .then(response => {
                    setBooking(response.data.booking);
                })

        }
    }, [booking, id]);

    const hasParking = useMemo(() => {
        return booking?.order_items.findIndex(s => s.name === 'parking') > -1;
    }, [booking]);

    useEffect(() => {
        register('action')
    }, []);

    useEffect(() => {
        history.replace(location.pathname, {
            ...location.state,
            successText,
        });
    }, [successText]);

    const action = watch('action');

    if (!location.state.token) {
        return <Redirect to={'/scan-qr-code/'}/>;
    }

    if (!booking) {
        return <LoadingPage/>;
    }

    if (successText) {
        return <SuccessBooking {...{booking}}/>;
    }

    if (booking.status === 'pending') {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{height: "100%"}}>
                <Card border="light">
                    <Card.Body className="text-center">
                        <i className="bi bi-exclamation-circle text-warning" style={{fontSize: "10rem"}}/>
                        <Card.Text>
                            {t('this_order_is_not_approved_yet')}
                        </Card.Text>
                        <div className="d-grid gap-2">
                            <Link variant="primary" className="btn btn-primary" to={`/bookings/${booking.id}`}>
                                {t('show_order_details')}
                            </Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    if (booking.status === 'rejected') {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{height: "100%"}}>
                <Card border="light">
                    <Card.Body className="text-center">
                        <i className="bi bi-x-circle text-danger" style={{fontSize: "10rem"}}/>
                        <Card.Text>
                            {t('this_order_has_been_rejected')}
                        </Card.Text>
                        <div className="d-grid gap-2">
                            <Link variant="primary" className="btn btn-primary"
                                  to={`/bookings/${booking.id}`}>{t('show_order_details')}</Link>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    const isInstant = !!booking.instant_booking;

    return (
        <Form noValidate onSubmit={handleSubmit(updateBooking)}>
            <Helmet>
                <title>{t('edit')} #{booking.id + ''} / {t('drawer_link_titles.bookings')} - {t('app')}</title>
            </Helmet>

            <ConfirmDialog
                message={'conform_dialog.new_parking_price_will_be_calculated_and_order_will_be_extended'}
                cancel={'conform_dialog.cancel'}
                accept={'conform_dialog.extend'}
                acceptAction={handleSubmit(extendConfirmed)}
                show={confirmExtend}
                handleClose={handleClose}
            />

            <Card className="px-2 order-card">
                <Card.Body className="px-2">
                    <p className="extra-small">{booking.site?.name}</p>
                    <p className="extra-small">
                        {moment(booking.created_at).format('HH:mm')} - {moment(booking.created_at).format('l')}
                    </p>
                    <p className="extra-small">{booking.user?.name}</p>
                    <p className="extra-small">{booking.vehicle?.registration_number}</p>
                    {
                        hasParking && (
                            <p className="extra-small">
                                {t('instant_booking.parking')}: {booking.duration < 24 ? t('n_hours', {count: booking.duration}) : t('n_days', {count: Math.round(booking.duration / 24)})}
                            </p>
                        )
                    }
                    {
                        booking.from_datetime && booking.to_datetime &&
                        <p>
                            {`${t('from')} `}
                            {moment.utc(booking.from_datetime).tz(timezone).format(t('formats.short_datetime_reverse'))}
                            {` ${t('to')} `}
                            {moment.utc(booking.to_datetime).tz(timezone).format(t('formats.short_datetime_reverse'))}
                        </p>
                    }
                    <input type="checkbox" value={0} {...register('services[]')} checked className="d-none"/>
                    {
                        (booking.order_items.filter(orderItem => orderItem.name !== 'parking').length > 0)
                        && (
                            <>
                                <p className="extra-small mb-2">{t('addons')}</p>
                                <div className="text-danger d-inline-flex extra-small gap-2 mb-2 align-items-center">
                                    <img src="/images/error-icon.png" alt="error-icon"
                                         className="img-fluid error-icon-small"/>
                                    <p className="m-0 extra-small">{t('instant_booking.check_off')}</p>
                                </div>

                                {booking.order_items.map((orderItem, key) => {
                                    return orderItem.name !== 'parking' && <div className={
                                        orderItem.item_claimed_at != null ?
                                            'mb-2 p-2 rounded gap-2 text-white d-flex checkbox-addons-bg' :
                                            'mb-2 p-2 rounded gap-2 text-white d-flex bg-primary'
                                    }>
                                        <input
                                            type="checkbox"
                                            defaultChecked={orderItem.item_claimed_at != null}
                                            disabled={orderItem.item_claimed_at != null}
                                            className="rounded form-check-input"
                                            id={`check${orderItem.id}`}
                                            value={orderItem.id}
                                            {...register('services[]')}
                                        />
                                        <label htmlFor={`check${orderItem.id}`} className="text-capitalize">
                                            {orderItem.name}
                                        </label>
                                    </div>
                                })}
                            </>
                        )
                    }
                    <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.services && errors.services.message}
                    </Form.Control.Feedback>
                    {
                        booking.from_datetime && booking.to_datetime &&
                        <Row className="mt-3">
                            <Col md={6} className="col-12">
                                {action == 'extend' &&
                                    <Col md={6} className="col-12 mb-2">
                                        <Form.Control
                                            placeholder={t('to')}
                                            type="datetime-local"
                                            {...register('to_datetime')}
                                            min={moment(booking.to_datetime).add(1, 'hour').format('YYYY-MM-DDTHH:mm')}
                                            isInvalid={!!errors.to_datetime}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {errors.to_datetime && errors.to_datetime.message}
                                        </Form.Control.Feedback>
                                    </Col>
                                }
                                <div className="d-grid gap-2">
                                    {action == 'extend' && (
                                        <>
                                            <Button onClick={() => setConfirmExtend(true)}
                                                    variant="outline-success"
                                                    type="button" className="mb-2">{t('update')}</Button>

                                            <Button onClick={() => setValue('action', 'update')}
                                                    variant="outline-danger"
                                                    type="button" className="mb-2">{t('cancel_extend')}</Button>
                                        </>
                                    ) || (
                                        <Button onClick={() => setValue('action', 'extend')}
                                                variant="outline-primary"
                                                type="button" className="mb-2">{t('extend_booking')}</Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    }
                    {
                        booking.vehicle_checked_out_at != null &&
                        <Row className="mx-0 mt-2">
                            <Col className="col-12 mt-2 p-0">
                                <div className="alert alert-danger" role="alert">
                                    <h5 className="font-weight-bold mb-2">{t('driver_checked_out_at', {checkedOutTime: booking.vehicle_checked_out_at})}</h5>
                                </div>
                            </Col>
                        </Row>
                    }
                    <Row className="mx-0 mt-2">
                        <Col className="col-12 mt-2 p-0">
                            {
                                !updating ?
                                    <div className="d-grid gap-2">
                                        {(booking.order_items.filter(orderItem => orderItem.name !== 'parking').length > 0)
                                            && (
                                                <Button type="submit"
                                                        variant="success">{t('instant_booking.save_changes')}</Button>
                                            )}

                                        {!isInstant && booking.vehicle_checked_in_at == null && (
                                            <Button onClick={_ => {
                                                setValue('action', 'check_in');
                                                handleSubmit(checkIn)()
                                            }} variant="secondary">
                                                {t('check_in')}
                                            </Button>
                                        )}
                                        {(booking.vehicle_checked_in_at != null && booking.vehicle_checked_out_at == null && !isInstant) && (
                                            <Button onClick={_ => {
                                                setValue('action', 'check_out');
                                                handleSubmit(checkOut)()
                                            }} variant="outline-danger">
                                                {t('check_out')}
                                            </Button>
                                        )}
                                    </div>
                                    :
                                    <div className="d-grid gap-2">
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            <span className="mx-2">{t('please_wait')}</span>
                                        </Button>
                                    </div>
                            }
                            {
                               !!booking.instant_booking &&
                                <div className="d-grid gap-2">
                                    <Button className="w-100" variant="outline-primary"
                                            onClick={() => history.replace('/scan-qr-code')}>
                                        {t('instant_booking.back_to_scanner')}
                                    </Button>
                                    <Button className="w-100" variant="primary" onClick={() => history.replace('/')}>
                                        {t('go_to_dashboard')}
                                    </Button>
                                </div>
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Form>
    )
}

export default Edit;
