import React, {Component, useEffect, useState} from 'react'
import QrScan from 'react-qr-reader'
import {useTranslation} from "react-i18next";
import {Button, Card, Form} from "react-bootstrap";
import {useAuth} from "@/services/Auth";
import {Redirect, useHistory} from "react-router-dom";
import LoadingPage from "@/components/LoadingPage";
import {DistanceMatrixService, LoadScript} from "@react-google-maps/api";
import {Helmet} from "react-helmet";

function ScanQRCode(props) {
    let auth = useAuth();
    const history = useHistory();
    const {t, i18n} = useTranslation();

    let mapAPIKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || "AIzaSyDW4T1E4m-JcphqrsiGtRzo0xd_7XXx7WU";

    const postRequest = auth.postRequest;

    const [manualId, setManualId] = useState(false);
    const [locationHasError, setLocationError] = useState(false);

    const [instantBookingToken, setInstantBookingToken] = useState(false);
    const [loadDistance, setLoadDistance] = useState(false);
    const [loading, setLoading] = useState(null);
    const [site, setSite] = useState(null);
    const [booking, setBooking] = useState(null);
    const [serverErrors, setServerErrors] = useState(null);
    const [currentLocation, setCurrentLocation] = useState([{lat: 0, lng: 0}]);

    const processUrlCode = () => {
        const codeData = decodeUrlCode(props.location);
        if (codeData) {
            handleScan(codeData);
        }
    }

    const handleInstantBookingCode = (token) => {
        setLoading(true);
        if (auth.user.is_operator || auth.user.is_site_manager) {
            auth.postRequest('instant_booking/code', {
                instant_booking_token: token,
            })
                .then((res) => {
                    let allSites = res.data.sites;
                    if (allSites.length === 0) {
                        return setServerErrors([t('instant_booking.no_sites')])
                    }
                    const currentSelectedSite = localStorage.getItem('entity');
                    const selectedSiteIndex = allSites.findIndex((site) => site.id == JSON.parse(currentSelectedSite).type_id);
                    const canBeSelected = selectedSiteIndex > -1;
                    if (!canBeSelected) {
                        return setServerErrors([t('instant_booking.current_sites_no_instant_booking')]);
                    }
                    let sessionSite = allSites[selectedSiteIndex] || JSON.parse(sessionStorage.getItem('site'));
                    if (!sessionSite || sessionSite == undefined){
                        sessionSite = allSites[0];
                        sessionStorage.setItem('site', JSON.stringify(sessionSite));
                    }
                    const baseData = {
                        driver: res.data.driver,
                        vehicle: res.data.vehicle,
                        site: sessionSite,
                        canChange: allSites.length !== 1,
                        sites: allSites
                    }
                    history.push('/instant-booking/confirmation', baseData);
                })
                .catch(() => {
                    setServerErrors([t('instant_booking.invalid_code')])
                })
                .finally(() => setLoading(false));
        }
    }

    const decodeUrlCode = (url) => {
        const urlParams = new URLSearchParams(url.search);

        if (!urlParams.has('code')) {
            return '{}';
        }

        try {
            return atob(urlParams.get('code'));
        } catch (error) {
            return '{}';
        }
    }

    const handleScan = (data) => {
        if (data) {

            if (isValidUrl(data)) {
                data = decodeUrlCode(new URL(data));
            }

            let dataObject = JSON.parse(data);

            if (dataObject.instant_booking) {
                return handleInstantBookingCode(dataObject.instant_booking.token);
            }

            if (dataObject.site) {
                setSite(dataObject.site);
            }

            if (dataObject.booking && dataObject.booking.token && dataObject.booking.id) {
                setBooking(dataObject.booking);
            }
        }
    }

    const getSiteDistance = () => {
        setLoadDistance(true);
    }

    const initializeInstantBooking = () => {
        if (!currentLocation) {
            return getLocation();
        }

        if (!site) {
            return false;
        }

        setLoading(true)

        auth.postRequest(`/sites/code/${site.id}`, {
            token: site.token,
            longitude: currentLocation.lng,
            latitude: currentLocation.lat,
        })
            .then(response => {
                setInstantBookingToken(response.data.instant_order_token);
            })
            .catch(error => {
                if (error.response.status === 422) {
                    let serverErrors = [];
                    for (const key in error.response.data.errors) {
                        serverErrors.push(
                            error.response.data.errors[key][0]
                        )
                    }
                    setServerErrors(serverErrors)
                }
            })
            .finally(_ => setLoading(false))
    }


    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, locationError);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    };

    const locationError = () => {
        setCurrentLocation(null);
        setLocationError(true);
    }

    const showPosition = (position) => {
        setLocationError(false);

        setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        });

    };

    const handleError = err => {
        console.error(err)
    }

    const isValidUrl = urlString => {
        const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }

    useEffect(() => {
        getLocation();
        processUrlCode();
    }, []);

    useEffect(() => {
        initializeInstantBooking();
    }, [site, currentLocation])


    if (site && instantBookingToken) {
        return <Redirect to={
            {
                pathname: `/bookings/site-card/${site.id}`,
                state: {
                    from: props.location,
                    start: new Date(),
                    instant_order_token: instantBookingToken,
                }
            }
        }/>;
    }

    if (booking) {
        return <Redirect to={
            {
                pathname: `/bookings/${booking.id}/edit`,
                state: {
                    from: props.location,
                    token: booking.token
                }
            }
        }/>;
    }

    if (loading) {
        return <LoadingPage/>;
    }


    return (
        <div className="px-2 mt-3" style={{height: "100%"}}>
            <Helmet>
                <title>{t('drawer_link_titles.scan_qr_code')} - {t('app')}</title>
            </Helmet>

            <Card>
                {
                    serverErrors && serverErrors.map && serverErrors.map((error) => {
                        return <p className="text-danger">{t(error)}</p>
                    }) || (
                        <>

                            {
                                !manualId &&
                                <Card.Title className="row d-flex justify-content-center scan-section"
                                            style={{minHeight: 500}}>
                                    <QrScan
                                        delay={300}
                                        onError={handleError}
                                        onScan={handleScan}
                                        style={{height: 350, width: "100%", maxWidth: 450}}
                                    />
                                </Card.Title>
                            }
                            <Card.Body className="text-center">
                                {
                                    locationHasError &&
                                    <p className="text-danger">{t('gr_code_location_error')}</p>
                                }
                            </Card.Body>

                        </>
                    )
                }
            </Card>
        </div>

    );
}

export default ScanQRCode;
