import Addons from './Addons';
import CallToAction from './CallToAction';
import Details from './Details';
import Gallery from './Gallery';
import MediaWithContent from './MediaWithContent';
import Pricing from './Pricing';
import Services from './Services';
import Video from './Video';

export {
  Addons,
  CallToAction,
  Details,
  Gallery,
  MediaWithContent,
  Pricing,
  Services,
  Video,
};

export default {
  Addons,
  CallToAction,
  Details,
  Gallery,
  MediaWithContent,
  Pricing,
  Services,
  Video,
};
