import {Button, Card, Form, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import Select from "react-select";
import ParkingPriceFields from "./ParkingPriceFields";
import LoadingButton from "@/components/LoadingButton";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import ConfirmDialog from "../../../../components/Modals/ConformDialog";
import {useAuth} from "@/services/Auth";
import LoadingPage from "@/components/LoadingPage";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import moment from 'moment-timezone';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'


function SiteForm({
                      initialValues,
                      handleOnSubmit,
                      site,
                      handleSuccess,
                      buttonLoading,
                      siteAssignedSupplier,
                      action,
                      serverErrors,
                      isEdit
                  }) {

    let auth = useAuth();

    let baseUrl = auth.baseUrl;

    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);

    const [addedSiteImage, setAddedSiteImage] = useState([]);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [imageToDelete, setImageToDelete] = useState('');
    const [siteImageError, setSiteImageError] = useState(null);


    const [serviceCategories, setServiceCategories] = useState([]);
    const [siteFacilities, setSiteFacilities] = useState([]);
    const [securityFacilities, setSecurityFacilities] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [accountStatusOptions, setAccountStatusOptions] = useState([]);
    const [accountTypeOptions, setAccountTypeOptions] = useState([]);
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [supportedCurrency, setSupportedCurrency] = useState([]);
    const [siteFacilityOptions, setSiteFacilityOptions] = useState([]);
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [securityFacilityOptions, setSecurityFacilityOptions] = useState('');
    const [commissionRateTypeOptions, setCommissionRateTypeOptions] = useState('');


    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [spaces, setSpaces] = useState(site.spaces);

    const [siteSupplier, setSiteSupplier] = useState(null);


    const shape = {
        marketing_status: Yup.string().oneOf(['Active', 'Inactive', 'Cancelled']),
        approval_status: Yup.string().oneOf(['Active', 'Inactive']),
        spaces: Yup.array()
            .when(['approval_status'], (approvalStatus, schema) => {
                return approvalStatus === 'Active' &&
                    schema.of(
                        Yup.object().shape({
                            space: Yup.number().required()
                                .min(0)
                                .test('maxValue', 'Invalid Value', val => val < 999)
                        })
                    ) || schema.nullable();
            }),
        driver_message: Yup.string().nullable().max(400),
        default_space: Yup.number().min(0),
    };

    if (auth.roles.create_site) {
        Object.assign(shape, {
            supplier_id: auth.user.is_platform_admin && action === 'create' && Yup.number().required(t('form_validation.supplier_is_required')) || null,
            name: Yup.string().required(t('form_validation.site_name_is_required')),
            phone_number: Yup.string().required(t('form_validation.phone_number_is_required')),
            description: Yup.string().required(t('form_validation.description_is_required')),
            video_url: Yup.lazy(value => {
                if (!value) {
                    return Yup.string();
                }
                return Yup.string().matches(
                    /^https:\/\/(?:player\.vimeo\.com\/video\/\d+(?:\?[^#]+)?|www\.youtube\.com\/embed\/[\w-]+(?:\?[^#]+)?)$/,
                    t('form_validation.video_url_is_valid')
                );
            }),
            total_spaces: Yup.number().required(t('form_validation.total_spaces_available_is_required')),
            currency: Yup.string().required(t('form_validation.site_currency_is_required')),
            longitude: Yup.string().required(t('form_validation.longitude_is_required')),
            latitude: Yup.string().required(t('form_validation.latitude_is_required')),
            address: Yup.string().required(t('form_validation.address_is_required')),
            second_line_address: Yup.string().nullable(),
            city: Yup.string().required(t('form_validation.city_town_is_required')),
            country_id: Yup.number().required(t('form_validation.country_is_required'))
                .transform(v => v === '' || isNaN(v) ? null : v).nullable(),
            county: Yup.string().required(t('form_validation.county_is_required')),
            post_code: Yup.string().required(t('form_validation.post_code_is_required')),
            email: Yup.string().email().required(t('form_validation.email_address_is_required')),
            vat_registration_number: Yup.string().required(t('form_validation.is_required', {attribute: t('vat_registration_number')})),

            profile_image: Yup.string().nullable(),

            supplier_payment_terms: Yup.string().required(t('form_validation.client_payment_terms_required')),
            supplier_payment_terms_days: Yup.number().required(t('form_validation.client_payment_terms_days_required'))
                .transform(v => v === '' || isNaN(v) ? null : v).nullable(),

            security_rating: Yup.number().required(),
            parking_available: Yup.boolean(),
            prebookable: Yup.boolean().when(['parking_available', 'instant_booking'], {
                is: (parking_available, instantBooking) => {
                    return parking_available && !instantBooking;
                },
                then: Yup.boolean().oneOf([true], t('form_validation.instant_prebook_required')),
                otherwise: Yup.boolean()
            }),
            instant_booking: Yup.boolean().when(['parking_available', 'prebookable'], {
                is: (parking_available, preBookable) => {
                    return parking_available && !preBookable;
                },
                then: Yup.boolean().oneOf([true], t('form_validation.instant_prebook_required')),
                otherwise: Yup.boolean()
            }),

            parking_prices: Yup.array().of(Yup.object().shape({
                price: Yup.number().required(t('form_validation.parking_price_is_required')),
                period_value: Yup.number().min(1, t('form_validation.min', {
                    attribute: t('period_value'),
                    value: 1
                })).required(t('form_validation.parking_price_period_value_is_required')),
                per_period_type: Yup.string().required(t('form_validation.parking_price_per_period_type_is_required')),
                up_to_value: Yup.number().min(1, t('form_validation.min', {
                    attribute: t('up_to_value'),
                    value: 1
                })).required(t('form_validation.parking_price_up_to_value_is_required')),
                up_to_period: Yup.string().required(t('form_validation.parking_price_up_to_period_is_required')),
            })),

            services: Yup.array().of(Yup.object().shape({
                name: Yup.string().required(t('form_validation.name_is_required')),
                category_id: Yup.number().required(t('form_validation.category_is_required')),
                price: Yup.number().required(t('form_validation.price_is_required')),
            })),

            attachments: Yup.array().of(Yup.object().shape({
                hash: Yup.string().required(),
            })),

            commission_rate_type: Yup.string().required(t('form_validation.commission_rate_type_is_required')),
            commission_rate_value: Yup.number().required(t('form_validation.commission_value_is_required')),

            commission_rates: Yup.array().of(Yup.object().shape({
                rate_type: Yup.string().required(t('form_validation.commission_rate_type_is_required')),
                rate_value: Yup.number().required(t('form_validation.commission_value_is_required')),
            })),

            facilities: Yup.array().of(Yup.number()),
            security_facilities: Yup.array().of(Yup.number()),

            marketing_status: Yup.string().oneOf(['Active', 'Inactive', 'Cancelled']),
            marketing_auto_price: Yup.boolean(),

            account_items: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string()
                        .required(t('form_validation.is_required', {attribute: t('title')})),
                    renew_date: Yup.date()
                        .typeError(t('form_validation.is_required', {attribute: t('renew_date')}))
                        .required(t('form_validation.is_required', {attribute: t('renew_date')})),
                    unit_price: Yup.number()
                        .required(t('form_validation.is_required', {attribute: t('unit_price_gbp')})),
                    quantity: Yup.number()
                        .min(1, t('form_validation.min', {attribute: t('quantity'), value: 1}))
                        .required(t('form_validation.is_required', {attribute: t('quantity')})),
                }),
            ),
        });
    }

    const schema = Yup.object().shape(shape, [['prebookable', 'instant_booking']]).noUnknown(true);

    const {
        handleSubmit,
        register,
        reset,
        watch,
        control,
        formState: {errors},
        setValue,
        getValues,
    } = useForm({
        defaultValues: (_ => {
            const values = {...site};

            values.marketing_auto_price = values.marketing_auto_price ? 1 : 0;
            values.profile_image = site?.profile_image?.hash;

            values.facilities = values.facilities.map && values.facilities.map(e => e.id) || [];
            values.security_facilities = values.security_facilities.map && values.security_facilities.map(e => e.id) || [];

            return values;
        })(),
        resolver: yupResolver(schema),
    });

    const getOptions = () => {
        setLoading(true);
        auth.getRequest('sites/options')
            .then(response => {
                //options
                setServiceCategories(response.data.service_categories);
                setSiteFacilityOptions(response.data.facilities);
                setSecurityFacilityOptions(response.data.security_facilities);
                setAccountStatusOptions(response.data.account_status_options);
                setAccountTypeOptions(response.data.account_type_options);
                setPaymentTerms(response.data.payment_terms);
                setSupportedCurrency(response.data.supported_currency);
                setCommissionRateTypeOptions(response.data.commission_rate_options);
                setCities(response.data.cities);
                setCountries(response.data.countries);
                setSuppliers(response.data.suppliers);

                setCity(site.city);
                setCountry(site.country);

                setSiteFacilities(site.facilities.map(siteFacility => {
                    return {value: siteFacility.id, label: siteFacility.name}
                }));
                setSecurityFacilities(site.security_facilities.map(securityFacility => {
                    return {value: securityFacility.id, label: securityFacility.name}
                }));

                setCity(site.city)
                setCountry(site.country)
                setLoading(false);


                if (siteAssignedSupplier != null) {
                    let supplier = response.data.suppliers.find(supplier => supplier.id == siteAssignedSupplier);
                    setSiteSupplier({
                        value: supplier.id,
                        label: supplier.company_name,
                    });
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const securityRating = watch('security_rating');
    const profile_image = watch('profile_image');
    const attachments = watch('attachments');
    const parking_available = watch('parking_available');

    const {fields: parkingPrices, remove: removePrice, append: appendPrice} = useFieldArray({
        control,
        name: 'parking_prices',
    });

    const {fields: serviceRows, remove: removeService, append: appendService} = useFieldArray({
        control,
        name: 'services',
    });

    const {fields: commission_rates, setValue: setCommisionRates} = useFieldArray({
        control,
        name: 'commission_rates',
    });

    const {fields: account_items, append: appendItem, remove: removeItem, replace: replaceItems} = useFieldArray({
        control,
        name: 'account_items',
    });

    const approval_status = watch('approval_status');
    const prebookable = watch('prebookable');
    const marketing_status = watch('marketing_status');
    const marketing_auto_price = watch('marketing_auto_price');

    const services = watch('services');

    useEffect(() => {
        register('profile_image');
        register('attachments');
        register('parking_prices');
        register('services');
        register('commission_rates');

        register('facilities');
        register('security_facilities');

        getOptions();
    }, []);

    useEffect(() => {
        var new_commission_rates = [].concat(commission_rates);

        for (var i in services) {
            const service = services[i];

            if (service.category_id && !new_commission_rates.find(rate => service.category_id == rate.category_id)) {
                new_commission_rates.push({
                    category_id: service.category_id,
                    name: service.category?.name,
                    rate_type: null,
                    rate_value: null,
                });
            }
        }

        const categories = [];


        new_commission_rates = new_commission_rates.filter((rate, index) => {

            if (!rate.name && rate.category_id && serviceCategories) {
                const category = serviceCategories.find(_ => _.id == rate.category_id);

                rate.name = category?.name;
            }

            const other = new_commission_rates.findIndex(_ => _.category_id == rate.category_id)

            return !!services.find(service => service.category_id == rate.category_id) && (other == -1 || other >= index);
        })

        setValue('commission_rates', new_commission_rates);
    }, [services, serviceCategories]);

    useEffect(() => {
        if (marketing_status == 'Active' && marketing_auto_price > 0) {
            replaceItems([
                {
                    name: 'Marketing Licence',
                    renew_date: account_items[0] && account_items[0].renew_date || moment().format('YYYY-MM-DD'),
                    unit_price: auth.marketing_licence_price,
                    quantity: 1,
                }
            ])
        }
    }, [marketing_status, marketing_auto_price, auth.marketing_licence_price]);

    const confirmDelete = (image) => {
        setImageToDelete(image)
    };

    const deleteImage = () => {
        setValue('attachments', attachments.filter(_ => _ !== imageToDelete));
        setImageToDelete(null);
    };

    const addSiteImage = (image) => {
        setAddedSiteImage(image.target.value)
        setSiteImageError(null);

        let imageData = image.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            const file_base64 = reader.result.split(',')[1];


            setUploadingImage(true);
            auth.postRequest('/attachments', {
                name: imageData.name,
                mime_type: imageData.type,
                file_base64
            })
                .then(response => {
                    setValue('attachments', [].concat(attachments).concat([response.data.data]).filter((image) => !!image));
                    setUploadingImage(false);
                })
                .catch(error => {
                    setUploadingImage(false);

                    if (error.response.status === 422) {
                        if ('image' in error.response.data.errors) {
                            setSiteImageError(error.response.data.errors['image'][0])
                        }
                    }
                });

        }

        reader.readAsDataURL(imageData);
    };

    const onSave = (values) => {
        if (values.phone_number) {
            values.phone_number = values.phone_number.startsWith('+') ? values.phone_number : '+'.concat(values.phone_number)
        }

        handleOnSubmit(values)
    }

    console.log(errors);

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <div>

                <ConfirmDialog
                    message='delete_selected_image'
                    cancel='conform_dialog.cancel'
                    accept='conform_dialog.delete'
                    acceptAction={deleteImage}
                    show={!!imageToDelete}
                    handleClose={_ => setImageToDelete(null)}
                />

                <Form onSubmit={handleSubmit(onSave)}>
                    {auth.roles.create_site && (<>

                        <div className="form-group mt-4">
                            <div className="row">
                                {(auth.user.is_platform_admin && action === 'create') &&

                                    <div className="col-12 col-sm-6 col-md-3 mb-2">
                                        <label htmlFor="supplier_id" className="my-2">{t('supplier')}</label>
                                        <Select
                                            className={`react-select ${errors.supplier_id ? 'is-invalid' : ''}`}
                                            isClearable={true}
                                            value={siteSupplier}
                                            onChange={(e) => {
                                                setValue('supplier_id', e ? parseInt(e.value) : null)
                                                setSiteSupplier(e)
                                            }}
                                            placeholder={t('supplier')}
                                            options={suppliers.map(supplier => {
                                                return {value: supplier.id, label: supplier.company_name}
                                            })}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.supplier_id && errors.supplier_id.message}
                                        </Form.Control.Feedback>
                                    </div>
                                }

                                <div className="col-12 col-sm-6 col-md-3 mb-2">
                                    <label htmlFor="name" className="my-2">{t('site_name')}</label>
                                    <Form.Control
                                        {...register('name')}
                                        type="text"
                                        placeholder={t('site_name')}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name && errors.name.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-3 mb-2">
                                    <label htmlFor="name" className="my-2">{t('email_address')}</label>
                                    <Form.Control
                                        {...register('email')}
                                        type="text"
                                        placeholder={t('email_address')}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email && errors.email.message}
                                    </Form.Control.Feedback>
                                </div>


                                <div className="col-12 col-sm-6 col-md-3 mb-2">
                                    <label htmlFor="name" className="my-2">{t('phone_number')}</label>
                                    <Controller
                                        control={control}
                                        {...register('phone_number')}
                                        isInvalid={!!errors.phone_number}
                                        render={({field: {ref, ...field}}) => (
                                            <PhoneInput
                                                country={'gb'}
                                                {...field}
                                                inputExtraProps={{
                                                    ref
                                                }}
                                                inputClass={`${!!errors.phone_number ? 'is-invalid': ''}`}
                                                inputStyle={{
                                                    padding: '0.55rem 14px 0.55rem 60px',
                                                    width: 'inherit'
                                                }}
                                                placeholder={t('enter_phone_number')}
                                            />
                                        )}
                                    />
                                    <Form.Control
                                        type="hidden"
                                        isInvalid={!!errors.phone_number}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.phone_number && errors.phone_number.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-3 mb-2">
                                    <label htmlFor="name" className="my-2">{t('vat_registration_number')}</label>
                                    <Form.Control
                                        {...register('vat_registration_number')}
                                        type="text"
                                        placeholder={t('vat_registration_number')}
                                        isInvalid={!!errors.vat_registration_number}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vat_registration_number && errors.vat_registration_number.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12">
                                    <label htmlFor="name" className="my-2">{t('site_description')}</label>
                                    <Form.Control
                                        as="textarea"
                                        {...register('description')}
                                        placeholder={t('site_description')}
                                        isInvalid={!!errors.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description && errors.description.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12">
                                    <label htmlFor="name" className="my-2">{t('site_video_url')}</label>
                                    <Form.Control
                                        type="text"
                                        {...register('video_url')}
                                        placeholder={t('site_video_url')}
                                        isInvalid={!!errors.video_url}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.video_url && errors.video_url.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>


                        <div className="form-group mt-4">
                            <div className="row">
                                <h5>{t('images')}</h5>
                                <div className="col d-flex">
                                    {
                                        attachments?.map((image) => {
                                            return (
                                                <Card style={{
                                                    width: '10rem',
                                                    borderColor: image.hash != profile_image ? 'var(--bs-success)' : 'rgba(0,0,0,.125)'
                                                }} className="me-1 my-1" key={image.id}>
                                                    <Card.Img variant="top" src={image.url}/>

                                                    <Card.Footer>
                                                        <div className="d-flex justify-content-around">
                                                            <Button type="button" variant="danger"
                                                                    className="btn-icon btn-rounded"
                                                                    onClick={() => confirmDelete(image)}>
                                                                <i className="bi bi-x"></i>
                                                            </Button>
                                                            {
                                                                image.hash != profile_image &&
                                                                <Button type="button" variant="success"
                                                                        className="btn-icon btn-rounded"
                                                                        onClick={() => setValue('profile_image', image.hash)}>
                                                                    <i className="bi bi-check"></i>
                                                                </Button>
                                                            }
                                                        </div>
                                                    </Card.Footer>
                                                </Card>
                                            )
                                        })
                                    }

                                    <Card style={{width: '10rem'}} className="me-1 my-1">
                                        <div
                                            className="add-image-card d-flex justify-content-center align-items-center">
                                            <input id="add-site-image" type="file" value={addedSiteImage}
                                                   onChange={e => addSiteImage(e)}/>
                                            {
                                                uploadingImage ?
                                                    <Spinner animation="border" variant="primary"/>
                                                    :
                                                    <i className="bi bi-plus-lg"></i>
                                            }

                                        </div>
                                        {
                                            siteImageError &&
                                            <small>
                                                <div className="form-group mt-4">
                                                    <p className="text-danger font-weight-bold">{siteImageError}</p>
                                                </div>
                                            </small>
                                        }
                                    </Card>

                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-4">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <label htmlFor="name"
                                           className="my-2">{t('security_rating')} - {securityRating}</label>
                                    <input
                                        {...register('security_rating')}
                                        name="security_rating"
                                        type="range"
                                        className="form-range"
                                        min="0"
                                        max="5"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-4">
                            <h5>{t('address')}</h5>

                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-3">
                                    <label htmlFor="country_id" className="my-2">{t('country')}</label>
                                    <Select
                                        className={`react-select ${errors.country_id ? 'is-invalid' : ''}`}
                                        isClearable={true}
                                        value={country}
                                        onChange={(e) => {
                                            setValue('country_id', e ? parseInt(e.value) : '');
                                            setCountry(e)
                                        }}
                                        placeholder={t('country')}
                                        options={countries.map(country => {
                                            return {value: country.id, label: country.name}
                                        })}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.country_id && errors.country_id.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-3">
                                    <label htmlFor="address" className="my-2">{t('first_line_address')}</label>
                                    <Form.Control
                                        {...register('address')}
                                        type="text"
                                        placeholder={t('first_line_address')}
                                        isInvalid={!!errors.address}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.address && errors.address.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                    <label htmlFor="address">{t('address_line_2')}</label>
                                    <Form.Control
                                        {...register('second_line_address')}
                                        type="text"
                                        placeholder={t('address_line_2')}
                                        isInvalid={!!errors.second_line_address}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.second_line_address && errors.second_line_address.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-3">
                                    <label htmlFor="county" className="my-2">{t('county')}</label>
                                    <Form.Control
                                        {...register('county')}
                                        type="text"
                                        placeholder={t('county')}
                                        isInvalid={!!errors.county}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.county && errors.county.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-3">
                                    <label htmlFor="city" className="my-2">{t('town_city')}</label>
                                    <Form.Control
                                        {...register('city')}
                                        type="text"
                                        placeholder={t('town_city')}
                                        isInvalid={!!errors.city}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.city && errors.city.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-3">
                                    <label htmlFor="post_code" className="my-2">{t('post_code')}</label>
                                    <Form.Control
                                        {...register('post_code')}
                                        type="text"
                                        placeholder={t('post_code')}
                                        isInvalid={!!errors.post_code}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.post_code && errors.post_code.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-3">
                                    <label htmlFor="latitude" className="my-2">{t('latitude')}</label>
                                    <Form.Control
                                        {...register('latitude')}
                                        type="text"
                                        placeholder={t('latitude')}
                                        isInvalid={!!errors.latitude}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.latitude && errors.latitude.message}
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-12 col-sm-6 col-md-3">
                                    <label htmlFor="longitude" className="my-2">{t('longitude')}</label>
                                    <Form.Control
                                        {...register('longitude')}
                                        type="text"
                                        placeholder={t('longitude')}
                                        isInvalid={!!errors.longitude}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.longitude && errors.longitude.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-4">
                            <h5>{t('site_currency')}</h5>

                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-3">
                                    <Form.Select
                                        {...register('currency')}
                                        placeholder={t('billing_currency')}
                                        isInvalid={!!errors.currency}
                                    >
                                        <option value="">--{t('choose')}--</option>
                                        {supportedCurrency.map((currency) => <option value={currency}
                                                                                     key={currency}>{t(currency)}</option>)}
                                    </Form.Select>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.currency && errors.currency.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                            <h5>{t('supplier_payment_terms')}</h5>
                            <Form.Select
                                {...register('supplier_payment_terms')}
                                placeholder={t('supplier_payment_terms')}
                                isInvalid={!!errors.supplier_payment_terms}
                            >
                                <option value="">--{t('choose')}--</option>
                                {paymentTerms.map((paymentTerm) => <option key={paymentTerm}>{t(paymentTerm)}</option>)}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors.supplier_payment_terms && errors.supplier_payment_terms.message}
                            </Form.Control.Feedback>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                            <label htmlFor="supplier_payment_terms_days">{t('supplier_payment_terms_days')}</label>

                            <Form.Select
                                {...register('supplier_payment_terms_days')}
                                placeholder={t('supplier_payment_terms_days')}
                                isInvalid={!!errors.supplier_payment_terms_days}
                            >
                                <option value="">--{t('choose')}--</option>
                                <option value={7}>7</option>
                                <option value={14}>14</option>
                                <option value={30}>30</option>
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors.supplier_payment_terms_days && errors.supplier_payment_terms_days.message}
                            </Form.Control.Feedback>
                        </div>

                        <div className="form-group mt-4">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-3">
                                    <div className="form-check d-flex">
                                        <input
                                            {...register('parking_available')}
                                            name="parking_available"
                                            className="form-check-input"
                                            type="checkbox"
                                            value={1}/>
                                        <label className="form-check-label mx-2" htmlFor="parking_available">
                                            {t('parking_available')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-4">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-3">
                                    <div className="form-check d-flex">
                                        <input
                                            {...register('prebookable')}
                                            name="prebookable"
                                            className="form-check-input"
                                            type="checkbox"/>
                                        <label className="form-check-label mx-2" htmlFor="prebookable">
                                            {t('prebookable')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {errors.prebookable && (
                            <div className="feedback-error-info">
                                {errors.prebookable.message}
                            </div>
                        )}

                        <div className="form-group mt-4">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-3">
                                    <div className="form-check d-flex">
                                        <input
                                            {...register('instant_booking')}
                                            name="instant_booking"
                                            className="form-check-input"
                                            type="checkbox"/>
                                        <label className="form-check-label mx-2" htmlFor="prebookable">
                                            {t('instant.title')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {errors.instant_booking && (
                            <div className="feedback-error-info">
                                {errors.instant_booking.message}
                            </div>
                        )}

                        {
                            parking_available &&
                            <div className="form-group mt-4">
                                <div className="row">
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <label htmlFor="total_spaces" className="my-2">{t('spaces_available')}</label>
                                        <Form.Control
                                            {...register('total_spaces')}
                                            type="number"
                                            name="total_spaces"
                                            isInvalid={!!errors.total_spaces}
                                            placeholder={t('spaces_available')}
                                            className={`form-control`}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.total_spaces && errors.total_spaces.message}
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            parking_available &&
                            <div className="form-group mt-4 px-2">
                                <div className="row">

                                    <Table striped responsive bordered hover size="sm" className="mt-4">
                                        <thead>
                                        <tr>
                                            <th style={{minWidth: '50px'}}>{t('price')}</th>
                                            <th style={{minWidth: '120px'}}>{t('period_value')}</th>
                                            <th style={{minWidth: '120px'}}>{t('period_per')}</th>
                                            <th style={{minWidth: '120px'}}>{t('upto_value')}</th>
                                            <th style={{minWidth: '120px'}}>{t('upto_period')}</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            parkingPrices.map((parking_price, index) => (
                                                <ParkingPriceFields
                                                    errors={errors}
                                                    key={parking_price.id}
                                                    register={register}
                                                    watch={watch}
                                                    index={index}
                                                    remove={_ => removePrice(index)}
                                                />
                                            ))
                                        }
                                        <tr>
                                            <td>
                                                <div className="d-grid gap-2">
                                                    <Button variant="success" className="has-icon"
                                                            onClick={() => appendPrice({})
                                                            }>
                                                        <div
                                                            className="d-flex align-items-center justify-content-center">
                                                            <span>{t('add_parking_prices')}</span>
                                                            <i className="bi bi-plus"/>
                                                        </div>
                                                    </Button>
                                                </div>
                                            </td>
                                            <td colSpan={5}>&nbsp;</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        }

                        <div className="form-group mt-4 px-2">
                            <div className="row">
                                <Table striped responsive bordered hover size="sm" className="mt-4">
                                    <thead>
                                    <tr>
                                        <th style={{minWidth: '120px'}}>{t('service')}</th>
                                        <th style={{minWidth: '120px'}}>{t('category')}</th>
                                        <th style={{minWidth: '120px'}}>{t('price')}</th>
                                        <th>{t('drawer_link_titles.promotion')}</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        serviceRows.map((service, index) => (
                                            <SiteService
                                                errors={errors && errors.services && errors.services[index] || {}}
                                                key={service.id}
                                                register={register}
                                                site={site}
                                                service={service}
                                                index={index}
                                                remove={_ => removeService(index)}
                                                serviceCategories={serviceCategories}
                                            />
                                        ))
                                    }
                                    <tr>
                                        <td>
                                            <div className="d-grid gap-2">
                                                <Button variant="success" className="has-icon" onClick={() => {
                                                    appendService({
                                                        name: null,
                                                        category_id: null,
                                                        price: null,
                                                    })
                                                }}>
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <span>{t('add_service')}</span>
                                                        <i className="bi bi-plus"/>
                                                    </div>
                                                </Button>
                                            </div>
                                        </td>
                                        <td colSpan={4}>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </Table>

                            </div>
                        </div>

                        <div className="form-group mt-4">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <h5>{t('facilities')}</h5>

                                    <Select
                                        className="react-select"
                                        isMulti
                                        isClearable={true}
                                        value={siteFacilities}
                                        onChange={(e) => {
                                            setValue('facilities', e.map && e.map(_ => _.value) || []);
                                            setSiteFacilities(e);
                                        }}
                                        placeholder={t('facilities')}
                                        options={siteFacilityOptions.map(facility => {
                                            return {value: facility.id, label: facility.name}
                                        })}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.facilities && errors.facilities.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-4">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <h5>{t('security_facilities')}</h5>
                                    <Select
                                        className="react-select"
                                        isMulti
                                        isClearable={true}
                                        value={securityFacilities}
                                        onChange={(e) => {
                                            setValue('security_facilities', e.map && e.map(_ => _.value) || []);
                                            setSecurityFacilities(e)
                                        }}
                                        placeholder={t('security_facilities')}
                                        options={securityFacilityOptions.map(securityFacility => {
                                            return {value: securityFacility.id, label: securityFacility.name}
                                        })}/>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.security_facilities && errors.security_facilities.message}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-4 px-2">
                            <h5>{t('commission_rates')}</h5>
                            <div className="row">
                                <Table striped responsive bordered hover size="sm" className="mt-4 site-settings-table">
                                    <thead>
                                    <tr>
                                        <td className="d-flex align-items-center" style={{minWidth: '130px'}}>
                                            {t('global_commission')}
                                        </td>
                                        <td style={{minWidth: '120px'}}>
                                            <Form.Select
                                                {...register('commission_rate_type')}
                                                isInvalid={!!errors.commission_rate_type}
                                            >
                                                <option value="">--{t('choose')}--</option>
                                                {
                                                    commissionRateTypeOptions.map(commissionRateTypeOption => {
                                                        return (
                                                            <option value={commissionRateTypeOption.value}
                                                                    key={commissionRateTypeOption.value}>{commissionRateTypeOption.label}</option>
                                                        )
                                                    })
                                                }

                                            </Form.Select>
                                        </td>

                                        <td style={{minWidth: '130px'}}>
                                            <Form.Control
                                                {...register('commission_rate_value')}
                                                type="number"
                                                name={`commission_rate_value`}
                                                className={`form-control `}
                                                placeholder={t('commission_rate_value')}
                                                isInvalid={!!errors.commission_rate_value}
                                                step=".01"
                                                min="0"
                                            />
                                        </td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        commission_rates.map((commission_rate, index) => (
                                            <SiteCommissionRate
                                                errors={errors && errors.commission_rates && errors.commission_rates[index] || {}}
                                                key={commission_rate.id}
                                                register={register}
                                                rate={commission_rate}
                                                index={index}
                                                commissionRateTypeOptions={commissionRateTypeOptions}
                                            />
                                        ))
                                    }
                                    </tbody>
                                </Table>

                            </div>
                        </div>
                    </>) || ''}


                    <h4 className="mt-4">{t('marketing.title')}</h4>
                    <p>{t('marketing.description')}</p>

                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                            <Form.Label className="my-2">
                                {t('marketing.active')}
                            </Form.Label>

                            <Form.Select
                                {...register('marketing_status')}
                                isInvalid={!!errors.marketing_status}
                                disabled={!auth.user.is_platform_admin && !site?.marketing_auto_price}
                            >
                                {
                                    ['Active', 'Cancelled'].includes(site?.marketing_status) &&
                                    <option value="Cancelled">{t('cancel')}</option>
                                }

                                {
                                    ['Inactive'].includes(site?.marketing_status || 'Inactive') &&
                                    <option value="Inactive">{t('no')}</option>
                                }

                                <option value="Active">{t('yes')}</option>
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                                {errors.marketing_status && errors.marketing_status.message}
                            </Form.Control.Feedback>
                            {
                                marketing_status == 'Cancelled' &&
                                <Form.Text id="marketing_status_helpblock" muted>
                                    {t('marketing.cancellation_info_block')}
                                </Form.Text>
                            }
                        </div>

                        {auth.user.is_platform_admin && (
                            <>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 my-3">
                                    <Form.Label className="my-2">
                                        {t('use_standard_price')}
                                    </Form.Label>

                                    <Form.Select
                                        {...register('marketing_auto_price')}
                                        isInvalid={!!errors.marketing_auto_price}
                                    >
                                        <option value={1}>{t('yes')}</option>
                                        <option value={0}>{t('no')}</option>

                                    </Form.Select>

                                    <Form.Control.Feedback type="invalid">
                                        {errors.marketing_auto_price && errors.marketing_auto_price.message}
                                    </Form.Control.Feedback>
                                </div>

                            </>
                        ) || ''}

                    </div>

                    {marketing_status != 'Inactive' && (
                        <>
                            <h5>{t('monthly_bill')}</h5>

                            <Table>
                                <thead>
                                <tr>
                                    <th>
                                        {t('title')}
                                    </th>

                                    <th>
                                        {t('renew_date')}
                                    </th>

                                    <th>
                                        {t('unit_price_gbp')}
                                    </th>


                                    <th>
                                        {t('quantity')}
                                    </th>

                                    {auth.user.is_platform_admin && marketing_auto_price < 1 && (
                                        <th/>
                                    )}
                                </tr>
                                </thead>

                                <tbody>
                                {account_items.map((account_item, index) => (

                                    <tr key={index}>
                                        <td>
                                            {auth.user.is_platform_admin && marketing_auto_price < 1 && (
                                                <>
                                                    <Form.Control
                                                        {...register(`account_items.${index}.name`)}
                                                        type="text"
                                                        isInvalid={!!errors.name}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.name && errors.name.message}
                                                    </Form.Control.Feedback>
                                                </>
                                            ) || account_item.name}
                                        </td>

                                        <td>
                                            {auth.user.is_platform_admin && marketing_auto_price < 1 && (
                                                <>
                                                    <Form.Control
                                                        {...register(`account_items.${index}.renew_date`)}
                                                        type="date"
                                                        isInvalid={!!errors.renew_date}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.renew_date && errors.renew_date.message}
                                                    </Form.Control.Feedback>
                                                </>
                                            ) || moment(account_item.renew_date).format(t('formats.date'))}
                                        </td>

                                        <td>
                                            {auth.user.is_platform_admin && marketing_auto_price < 1 && (
                                                <>
                                                    <Form.Control
                                                        {...register(`account_items.${index}.unit_price`)}
                                                        type="number"
                                                        isInvalid={!!errors.unit_price}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.unit_price && errors.unit_price.message}
                                                    </Form.Control.Feedback>
                                                </>
                                            ) || account_item.unit_price}
                                        </td>


                                        <td>
                                            {auth.user.is_platform_admin && marketing_auto_price < 1 && (
                                                <>
                                                    <Form.Control
                                                        {...register(`account_items.${index}.quantity`)}
                                                        type="number"
                                                        isInvalid={!!errors.quantity}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.quantity && errors.quantity.message}
                                                    </Form.Control.Feedback>
                                                </>
                                            ) || account_item.quantity}
                                        </td>

                                        {auth.user.is_platform_admin && marketing_auto_price < 1 && (
                                            <td>
                                                <Button type="button" onClick={_ => removeItem(index)} variant="danger">
                                                    {t('remove')}
                                                </Button>
                                            </td>
                                        )}

                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            {auth.user.is_platform_admin && marketing_auto_price < 1 && (
                                <Button type="button" onClick={_ => appendItem({})} variant="primary" size="sm">
                                    {t('add')}
                                </Button>
                            )}

                        </>
                    )}

                    {(isEdit && prebookable) && (
                        <>
                            <h4 className="mt-4">{t('approval.title')}</h4>
                            <p className="mb-4 small">{t('approval.description')}</p>
                            <Form.Label className="my-2">
                                {t('approval.status')}
                            </Form.Label>

                            <Form.Select
                                className="mb-4"
                                {...register('approval_status')}
                            >
                                <option value="Inactive">{t('no')}</option>
                                <option value="Active">{t('yes')}</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors.approval_status && errors.approval_status.message}
                            </Form.Control.Feedback>

                            {approval_status == 'Active' && (
                                <>
                                    <div className="mt-3 mb-4 mx-3">
                                        <div className="d-block d-md-none row">
                                            <div className="col border-1 input-box-style">
                                                {t('approval.days.spaces')}
                                            </div>
                                        </div>
                                        <div className="row row-cols-2 row-cols-md-1 d-md-block">
                                            <div className="row flex-column flex-md-row flex-grow-1 flex-md-grow-0">
                                                <div className="col border-1 input-box-style d-none d-md-block"></div>
                                                {spaces.map((space, index) => (
                                                    <div className="col border-1 input-box-style">
                                                        {t(`approval.days.${space.day}`)}
                                                    </div>
                                                ))}
                                                <div className="col border-1 input-box-style">
                                                    {t('approval.days.default')}
                                                </div>
                                            </div>
                                            <div className="row flex-column flex-md-row flex-grow-1 glex-md-grow-0">
                                                <div className="col border-1 input-box-style d-none d-md-block">
                                                    {t('approval.days.spaces')}
                                                </div>

                                                {spaces.map((space, index) => (
                                                    <>
                                                        <Form.Control
                                                            key={space.id}
                                                            {...register(`spaces.${index}.space`)}
                                                            className="col border-1 day-input rounded-0 input-box-style"
                                                            type="number"
                                                        ></Form.Control>
                                                    </>
                                                ))}
                                                <Form.Control
                                                    {...register('default_space')}
                                                    className="col border-1 rounded-0 day-input input-box-style"
                                                    type="number"
                                                ></Form.Control>
                                            </div>
                                        </div>
                                    </div>

                                    {spaces.map((space, index) => (
                                        errors.spaces && errors.spaces[index]?.space &&
                                        <div className="text-danger py-2 gap-2">
                                            {t('approval.invalid_value', {day: t(`approval.days.${space.day}`)})}
                                        </div>
                                    ))}
                                    {errors.default_space &&
                                        <div className="text-danger py-2 gap-2">
                                            {t('approval.invalid_value', {day: t(`approval.days.default`)})}
                                        </div>}

                                    <h4 className="mt-4">{t('approval.message.title')}</h4>
                                    <p>{t('approval.message.description')}</p>
                                    <Form.Control
                                        className="mb-4"
                                        {...register('driver_message')}
                                        as="textarea" rows={7}
                                    ></Form.Control>
                                    {errors.driver_message &&
                                        <div className="text-danger py-2 gap-2">
                                            {t('approval.invalid_message')}
                                        </div>
                                    }
                                </>
                            )}

                        </>)}


                    {
                        (serverErrors.length !== 0) &&
                        <div className="form-group mt-4">
                            {
                                serverErrors.map((error, index) => <p className="text-danger font-weight-bold"
                                                                      key={index}>{error}</p>)
                            }
                        </div>
                    }

                    <LoadingButton loading={buttonLoading} type="submit" titleTranslationKey='save_new_site'/>
                </Form>
            </div>
        )
    )
}

export default SiteForm;


function SiteService({register, index, service, site, errors, remove, serviceCategories}) {
    const {t} = useTranslation();

    return (
        <tr>
            <td>
                <div>
                    <Form.Control
                        {...register(`services.${index}.name`)}
                        type="text"
                        isInvalid={!!errors.name}
                    />
                </div>
            </td>

            <td>
                {
                    (service?.id) &&
                    <input
                        {...register(`services.${index}.id`)}
                        type="hidden"
                    /> || ''
                }

                <div>
                    <Form.Select
                        {...register(`services.${index}.category_id`)}
                        placeholder={t('service_category')}
                        isInvalid={!!errors.category_id}
                    >
                        <option value="">--{t('choose')}--</option>
                        {
                            serviceCategories.map(serviceCategory => serviceCategory.addon &&
                                <option key={serviceCategory.id}
                                        value={serviceCategory.id}>{serviceCategory.name}</option>)
                        }
                    </Form.Select>
                </div>
            </td>

            <td>
                <div>
                    <Form.Control
                        {...register(`services.${index}.price`)}
                        type="number"
                        step=".0000001"
                        min="0"
                        isInvalid={!!errors.price}
                    />
                </div>
            </td>

            <td>
                {
                    (service.promotion != null && service.id) ?
                        <Link to={`/promotions/edit/${service.promotion.id}`}>
                            {service.promotion.message}
                        </Link>
                        :
                        service.id ?
                            <Link to={`/promotions/create/${site.id}/${service.id}`}>{t('create')}</Link> : null
                }
            </td>

            <td>
                <Button variant="light" className="btn-icon btn-rounded" size="sm" onClick={remove}>
                    <i className="bi bi-x text-danger"/>
                </Button>
            </td>
        </tr>
    );
}


function SiteCommissionRate({register, index, rate, errors, commissionRateTypeOptions}) {
    const {t} = useTranslation();

    return (
        <tr>
            <td>
                <div>
                    <span>{rate.name}</span>
                </div>
            </td>

            <td>
                {
                    (rate?.category_id) &&
                    <input
                        {...register(`commission_rates.${index}.category_id`)}
                        type="hidden"
                    /> || ''
                }

                <div>
                    <Form.Select
                        {...register(`commission_rates.${index}.rate_type`)}
                        isInvalid={!!errors.rate_type}
                    >
                        <option value="">--{t('choose')}--</option>
                        {
                            commissionRateTypeOptions.map(commissionRateTypeOption => {
                                return (
                                    <option value={commissionRateTypeOption.value}
                                            key={commissionRateTypeOption.value}>{commissionRateTypeOption.label}</option>
                                )
                            })
                        }
                    </Form.Select>
                </div>
            </td>

            <td>
                <div>
                    <Form.Control
                        {...register(`commission_rates.${index}.rate_value`)}
                        type="number"
                        step=".0000001"
                        min="0"
                        isInvalid={!!errors.rate_value}
                    />
                </div>
            </td>
        </tr>
    );
}
