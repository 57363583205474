import React, {useEffect, useState, useRef}   from "react";
import {Link, useParams}              from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import Paginate from "../Paginate";
import useMoment from "../../hooks/useMoment";

function useColumns(filter) {
    let auth = useAuth();
    const {t} = useTranslation();

    const {momentLocal, moment} = useMoment();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let columns = [
        {
            dataField: 'message',
            text     : t('promotion_message'),
            formatter: (cell, row) => {
                return (auth.user.is_site_manager || auth.user.is_supplier_admin || auth.user.is_supplier_operator || auth.user.is_platform_admin) ? <Link to={`/promotions/edit/${row.id}`}>{cell}</Link> : <span>{cell}</span>
            }
        },
        {
            name: 'site',
            dataField: 'site.name',
            text     : t('site_name')
        },
        {
            name: 'product',
            dataField: 'product.name',
            text     : t('category')
        },
        {
            dataField: 'discount_formatted',
            text     : t('discount'),
            align    : 'right'
        },
        {
            dataField: 'expire_at',
            text     : t('expires'),
            formatter: cell => cell && momentLocal(cell).tz(timezone).format(t('formats.short_datetime'))
        },
    ];

    return filter && columns.filter(filter) || columns;

}

function Promotions({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const {t} = useTranslation();

    const perPage = props.perPage || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ... (query || {})};

        critera.take = perPage;
        critera.skip = page * perPage;

        request.current = auth.getRequest(url || '/promotions', critera);

        setRecords(false);

        request.current
            .then(response => {
                setRecords(response.data.promotions);
                setTotal(response.data.total);
            })
    }

    const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

    const changePage = ({ selected }) => {
        setPage(selected)
    }

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if(!recordsProp)
        {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <>
            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
                {...props}
            />
            {
                showPagination && records && totalRecords &&
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
                || ''
            }
        </>
    );
}

export default Promotions;

export {
    useColumns
};
